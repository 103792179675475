import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import GreetingCard from '../../components/greeting-card';

import './home.scss';

const Home = () => {
  const [deg, setDeg] = useState(0);

  const updateBackground = (event) => {
    const { clientX, clientY } = event;
    const wc = window.innerWidth / 2;
    const hc = window.innerHeight / 2;
    const delta = (clientX - wc) + (clientY - hc);
    setDeg(delta * 0.001);
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateBackground);
    return () => window.removeEventListener('mousemove', updateBackground);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Maneesh Chiba</title>
        <meta name="description" content="The personal website of Senior Front-end Developer, Maneesh Chiba"/>
      </Helmet>
      <section className={'home-layout'} style={{ '--background-offset': `${deg}deg` }}>
        <GreetingCard />
        <div className="introduction">
          <h2>
            I’m Maneesh Chiba,<br/><em>Senior Front-end Developer</em> &amp; UI designer.
            </h2>
          <p>I craft interfaces and push the boundaries of what the web can do.</p>
          <p>
            Formerly VMLY&amp;R, Mosaic Manufacturing, The Globe and Mail<br />Currently <a href="https://vanillaforums.com/" rel={'noopener noreferrer'} target={'_blank'}>Higher Logic.</a>
          </p>
          <p>
            Let’s create <a href="https://www.linkedin.com/in/maneeshchiba/">something amazing</a> together.
          </p>
        </div>
      </section>
    </>
  );
};

export default Home;
