import React, { useEffect, useRef, useState } from 'react';
import { scale } from '../../utils/helpers';

import './greeting-card.scss';

const GREETINGS = ['Hi!', 'Hello!', 'Hola!', 'Ciao!', 'Ola!', 'Jambo!', 'Hey!', 'Aloha!', 'Hej!'];

const GreetingCard = () => {
  const [size, setSize] = useState(null);
  const [mouseOffset, setMouseOffset] = useState([15, 0]);
  const simRef = useRef(null);
  const [classes, setClasses] = useState('');
  const [isPort, setPort] = useState(false);

  const getRandomInt = (min, max) => {
    return Math.floor(
      Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min),
    );
  };

  const spanify = (string) => {
    const letters = string.split('');
    return letters.map((char, index) => (<span key={index} data-char={char}>{char}</span>));
  };

  const [greeting, setGreeting] = useState(GREETINGS[getRandomInt(0, GREETINGS.length)]);

  const handleOrientation = (e) => {
    const xRot = scale(Math.floor(e.gamma * -1), -45, 45, -15, 15);
    const yRot = scale(Math.floor(e.beta), 0, 50, -15, 15);
    setMouseOffset([xRot, yRot]);
  };

  const handleMql = ({ matches }) => {
    if (matches) {
      setPort(true);
      setMouseOffset([0, 0]);
    } else {
      setPort(false);
      setMouseOffset([15, 0]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setClasses('collapse');
      setTimeout(() => {
        const newGreeting = GREETINGS[getRandomInt(0, GREETINGS.length)];
        if (newGreeting !== greeting) {
          setGreeting(newGreeting);
        } else {
          setGreeting(GREETINGS[getRandomInt(0, GREETINGS.length)]);
        }
      }, 700);
    }, 7000);

    window.addEventListener('deviceorientation', handleOrientation, true);

    //
    const mql = window.matchMedia('(orientation: portrait)');
    mql.addListener(handleMql);
    handleMql(mql);

    return () => {
      clearInterval(interval);
      window.removeEventListener('deviceorientation', handleOrientation, true);
      mql.removeListener(handleMql);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (classes.includes('collapse')) {
        setClasses('');
      }
    }, 800);
  }, [classes]);

  const handleMove = (event) => {
    const { clientX, clientY } = event;
    if (size) {
      const x = scale(
        Math.floor((clientX - size.x) - (size.width / 2)),
        0,
        (size.width / 2),
        0,
        20,
      );
      const y = scale(
        Math.floor((clientY - size.y) - (size.height / 2)),
        0,
        (size.height / 2),
        0,
        -20,
      );
      setMouseOffset([Math.floor(x), Math.floor(y)]);
    }
  };

  useEffect(() => {
    if (simRef) {
      setSize(simRef.current.getBoundingClientRect());
    }
  }, [simRef]);

  return (
    <div className="simulation"
      ref={simRef}
      onMouseMove={(e) => handleMove(e)}
      onMouseLeave={() => setMouseOffset([15, 0])}
      style={{ '--x': `${mouseOffset[0]}deg`, '--y': `${mouseOffset[1]}deg` }}
    >
      <div className={`greeting ${classes}`}>
        <span>{greeting}</span>
        <span>{greeting}</span>
        <span>{greeting}</span>
        <span>{greeting}</span>
        <span>{greeting}</span>
        <h1>
          {spanify(greeting)}
        </h1>
      </div>
    </div>
  );
};

export default GreetingCard;
